<template>
  <v-col v-if="loading" cols="12" class="text-center">
    <v-progress-circular color="primary" indeterminate />
  </v-col>
  <v-row v-else class="my-3">
    <v-col>
      <div class="font-weight-medium mb-2">Nama</div>
      <!-- v-if="data.id === null || data.id == undefined" -->
      <v-text-field outlined placeholder="Masukkan nama section" required v-model="section.name"></v-text-field>
      <!-- <v-text-field
        v-else
        outlined
        placeholder="Masukkan nama section"
        required
        v-model="data[0].name"
      ></v-text-field> -->
    </v-col>
    <v-col>
      <div class="font-weight-medium mb-2">Minimal Score</div>
      <!-- v-if="data.id === null || data.id == undefined"       -->
      <v-text-field type="number" outlined placeholder="Masukkan nilai minimal" required
        v-model="section.min_score"></v-text-field>
      <!-- <v-text-field
        v-else      
        type="number"
        outlined
        placeholder="Masukkan nilai minimal"
        required
        v-model="data[0].min_score"
      ></v-text-field> -->
    </v-col>
  </v-row>
</template>

<script>
// import model from "../_mixins/model";

export default {
  props: {
    data: { type: Object },
  },
  // mixins: [model],
  data: () => ({
    overlay: 0.1,
    overlay_color: "secondary",
    file: null,
    form: {},
    section: {},
    loading: false,
  }),
  computed: {
    customToolbar() {
      return [];
    },
  },
  watch: {
    section: {
      handler(val) {
        this.$set(this.data, "section", val);
      },
      deep: true,
    },
  },
  methods: {
    fetchDetail(id) {
      this.loading = true;
      this.axios
        .get(`/v1/section/${this.$route.params.id}/${id}/show`)
        .then((res) => {
          this.loading = false;
          let data = res.data.data;
          this.section = data;
          // this.$emit("update", data);
          this.$emit('action', data)
        });
    },
  },
  created() {
    if (Object.keys(this.data).length > 0) {
      this.fetchDetail(this.data.id)
    }
  }
};
</script>
