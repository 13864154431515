<template>
  <v-row>
    <v-col v-if="pageLoading" cols="12" class="text-center">
      <v-progress-circular color="primary" indeterminate />
    </v-col>
    <template v-else>
      <v-col v-if="loading" cols="12" class="text-center">
        <v-progress-circular color="primary" indeterminate />
      </v-col>
      <v-col v-if="!packets.length && !loading">
        <v-row justify="center">
          <v-col md="4" sm="6">
            <v-img class="col-10 mx-auto mt-12 mb-4" src="@/assets/images/data_empty.png"></v-img>
            <h6 class="text-h6 text-center">Tidak ada paket soal</h6>
            <p class="text-center text--secondary">
              klik tombol di bawah ini untuk menambahkan paket soal baru
            </p>
            <p class="text-center">
              <v-btn :loading="loadingPacket || loading" color="primary" @click="createPacket">
                <span> Buat Paket Soal </span>
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="packets.length && !loading">
        <v-expansion-panels focusable>
          <v-expansion-panel v-for="(paket, index) in packets" cols="12" :key="index" style="border: 1px solid #9db0c6;"
            class="mb-2">
            <v-expansion-panel-header :color="packetHasError(paket) ? 'red lighten-5' : ''" class="font-weight-bold">
              <h3>Paket Soal {{ index + 1 }}</h3>
              <div class="text-right">
                <v-btn :loading="loadingPacket || loading" icon @click.stop="confirmDeletePacket(paket)">
                  <v-icon> $delete </v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col v-if="loading" cols="12" class="text-center">
                <v-progress-circular color="primary" indeterminate />
              </v-col>
              <template v-else>
                <v-col v-if="!(paket.groups && paket.groups.length)">
                  <v-row justify="center">
                    <v-col md="4" sm="6">
                      <v-img class="col-10 mx-auto mt-12 mb-4" src="@/assets/images/data_empty.png"></v-img>
                      <h6 class="text-h6 text-center">Tidak ada section</h6>
                      <p class="text-center text--secondary">
                        klik tombol di bawah ini untuk menambahkan section baru
                      </p>
                      <p class="text-center">
                        <v-btn color="primary" class="mr-2" elevation="0" @click="addSection(paket)">
                          Buat Section
                        </v-btn>
                        <v-menu v-if="false" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-2" elevation="0">
                              Buat Soal
                              <v-icon class="ml-1">$arrdown</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="addQuestion(paket)">
                              <v-list-item-title>Soal Baru</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item @click="addGroup(paket)">
                              <v-list-item-title>Soal Grup</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else>
                  <v-row no-gutters>
                    <v-col v-if="loadingSections" cols="12" class="text-center">
                      <v-progress-circular color="primary" indeterminate />
                    </v-col>
                    <v-expansion-panels v-else accordion>
                      <v-expansion-panel v-for="(group, index2) in paket.groups" :key="index2"
                        style="border: 1px solid #9db0c6;" class="mb-2" @click="loadingSection">
                        <v-expansion-panel-header @click="setListQuestionBySection(group.list_question)">
                          <template v-if="loadingSoal">
                            <v-progress-circular indeterminate size="30" width="5" color="primary"
                              class="d-inline-block">
                            </v-progress-circular>
                          </template>
                          <template v-else>
                            <h3>Section {{ index2 + 1 }}</h3>
                            <div class="text-right">
                              <v-btn icon
                                @click.stop="confirmDeleteGroup(paket.id, paket.groups[index2], index2, index);">
                                <v-icon> $delete </v-icon>
                              </v-btn>
                            </div>
                          </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-col cols="12" class="px-0">
                            <v-card-title class="pt-4 ps-0 pb-2 text-subtitle-1 font-weight-bold">Form
                              Section</v-card-title>
                            <v-card outlined>
                              <v-form>
                                <input-section class="px-4" :data="paket.groups[index2].section"
                                  @update="$set(group, 'section', [...$event,])" @action="loadingSection" />
                              </v-form>
                            </v-card>
                          </v-col>
                          <v-card-title class="ps-0 pt-4 pb-2 text-subtitle-1 font-weight-bold">
                            Pertanyaan
                          </v-card-title>
                          <v-col v-if="loadingQuestion" cols="12" class="text-center">
                            <v-progress-circular color="primary" indeterminate />
                          </v-col>
                          <!-- BAGIAN SOAL -->
                          <v-expansion-panels v-else>
                            <v-expansion-panel v-for="(soal, indexq) in group.list_question" :key="indexq" cols="12"
                              class="mb-2 rounded" style="border: 1px solid #9db0c6;">
                              <v-expansion-panel-header class="font-weight-bold">
                                <div class="d-flex justify-space-between align-center"
                                  :class="group.list_question[0].id === undefined ? 'my-3' : ''">
                                  <div>
                                    <h3>
                                      Nomor
                                      {{ indexq + 1 }}
                                    </h3>
                                  </div>
                                  <v-btn v-show="group.list_question[0].id !== undefined" :loading="loadingQuestion"
                                    icon @click.stop="confirmDeleteQuestion(index, index2, indexq)">
                                    <v-icon> $delete </v-icon>
                                  </v-btn>
                                </div>
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row no-gutter>
                                  <v-col v-if="group.errors && group.errors.length" cols="12" class="mt-4">
                                    <v-alert type="error" outlined text dense>
                                      <ul v-for="(error, indexError) in group.errors" :key="indexError">
                                        <li>{{ error }}</li>
                                      </ul>
                                    </v-alert>
                                  </v-col>

                                  <input-soal v-model="group.list_question[indexq]" :isloading="loadingQuestion"
                                    :showBtnSave="group.list_question[0].id !== undefined"
                                    @save="saveNewQuestion($event, paket, group)" />
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <v-col v-if="group.errors && group.errors.length" cols="12">
                            <v-alert type="error" outlined text dense>
                              <ul v-for="(error, indexError) in group.errors" :key="indexError">
                                <li>{{ error }}</li>
                              </ul>
                            </v-alert>
                          </v-col>
                          <v-col v-if="group.list_question.length > 0">
                            <v-alert v-show="group.list_question[0].id !== undefined"
                              v-if="group.list_question.length != list_question_by_section.length || group.list_question[group.list_question.length - 1].id"
                              type="warning" outlined class="mx-0">
                              Pastikan soal terakhir sudah disimpan apabila ingin menambah soal baru
                            </v-alert>
                          </v-col>
                          <v-col>
                            <v-btn
                              v-if="group.list_question.length == list_question_by_section.length || group.list_question[group.list_question.length - 1].id"
                              :disabled="loadingQuestion" color="primary" class="mr-2" outlined
                              @click="addQuestion(group)">
                              Tambah Soal
                            </v-btn>
                            <v-btn v-if="group.list_question.length" class="float-right" color="primary"
                              :loading="loadingSections" @click="saveQuestions(packets[index], paket.groups[index2])">
                              Simpan section
                            </v-btn>
                          </v-col>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-btn :loading="loadingSections" outlined color="primary" @click="addSection(paket)">
                    <span> <v-icon>$add</v-icon> Tambah section</span>
                  </v-btn>
                </v-col>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-col cols="12">
          <v-btn :loading="loadingPacket || loading" outlined color="primary" @click="createPacket">
            <span> <v-icon>$add</v-icon> Tambah paket soal</span>
          </v-btn>
        </v-col>

        <v-dialog v-model="deletePacketModal.show" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span> <v-icon>$warning</v-icon> Hapus Paket Soal </span>
            </v-card-title>
            <v-divider></v-divider>
            <div>
              <v-card-text>
                Apakah anda ingin menghapus paket soal ini ?
              </v-card-text>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="deleting" outlined color="primary" large @click="cancelDeletePacket">
                Batal
              </v-btn>
              <v-btn :loading="deleting" class="white--text" color="primary" large @click="deletePacket">
                Hapus
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteGroupModal.show" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span> <v-icon>$warning</v-icon> Hapus Section Soal </span>
            </v-card-title>
            <v-divider></v-divider>
            <div>
              <v-card-text>
                Apakah anda ingin menghapus section soal ini ?
              </v-card-text>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="deleting" outlined color="primary" large @click="deleteGroupModal.show == false">
                Batal
              </v-btn>
              <v-btn :loading="deleting" class="white--text" color="primary" large @click="deleteGroup">
                Hapus
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="delete_question.show" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span> <v-icon>$warning</v-icon> Hapus Soal </span>
            </v-card-title>
            <v-divider></v-divider>
            <div>
              <v-card-text>
                Apakah anda ingin menghapus soal ini ?
              </v-card-text>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="deleting" outlined color="primary" large @click="cancelQuestion()">
                Batal
              </v-btn>
              <v-btn :loading="deleting" class="white--text" color="primary" large @click="deleteQuestion">
                Hapus
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="loadingSoal" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span> <v-icon>$warning</v-icon> Harap menunggu load data soal </span>
            </v-card-title>
            <v-divider></v-divider>
            <div>
              <v-card-text>
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </v-card-text>
            </div>
          </v-card>
        </v-dialog>
      </v-col>
    </template>

    <v-snackbar top v-model="snackbar.state" :timeout="3000" color="primary" outlined>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
const blankQuestion = {
  instruction: { text: "", media: [] },
  type: "",
  question_type: "text",
  answer_type: "text",
  question: { text: "", media: [] },
  point: 0,
  options: [],
  correct_answer: [],
  status: "publish",
  explanation: {
    correct: { text: "", media: [] },
    wrong: { text: "", media: [] },
  },
};
// import { mapState } from "vuex";
import InputSoal from "./SingleExam";
import InputSection from "./Section";
// import { VueEditor } from "vue2-editor";
// import QuestionOptions from "./Options";
// import InputGrupSoal from "./SingleQuestion";

export default {
  components: {
    InputSoal,
    InputSection
    // InputGrupSoal
  },
  data: () => ({
    // questions: [],
    pageLoading: null,
    loading: false,
    loadingPacket: false,
    loadingSections: false,
    loadingQuestion: false,

    submiting: false,
    deleting: null,
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"], // remove formatting button
    ],
    deletePacketModal: {
      show: false,
      packetId: null,
    },
    deleteGroupModal: {
      show: false,
      packetId: null,
      groupId: null,
    },
    delete_question: {
      show: false,
      id: null,
    },
    name: '',
    min_score: null,
    packets: [],
    sections: [],
    section: {},
    data_section: [],
    list_question_by_section: [],
    section_id: null,
    loadingSoal: false,
    open: null,
    snackbar: {
      state: false,
      text: ""
    },
  }),
  computed: {
    types() {
      return ["Multiple Choice"];
    },
    // ...mapState("packet", { packets: (state) => state.list }),
    // ...mapState("section", { sections: (state) => state.list }),
  },

  methods: {
    loadingSection(data) {
      this.loadingSoal = true
      setTimeout(() => {
        if (Object.keys(data).length > 0) {
          this.loadingSoal = false
        }
      }, 400)
    },
    async fetchPacket() {
      this.loading = true;
      await this.axios
        .get(
          `v1/packet/${this.$route.params.program}/${this.$route.params.topic}/${this.$route.params.id}/list`
        )
        .then((res) => {
          this.packets = res.data.data.list
          this.fetchQuestion();
          // console.log('list',this.packets)
        });
    },
    async fetchSection() {
      this.loadingSections = true;
      this.axios
        .get(
          `v1/section/${this.$route.params.id}/list`
        )
        .then((res) => {
          this.loadingSections = false;
          this.sections = res.data.data.list
          // this.fetchQuestion();          
          // console.log('list',this.packets)
        });
    },
    // getSection(id) {
    //   console.log('id sections',id)

    //   if (id !=null) {
    //     this.loading=true;        
    //     this.axios
    //       .get(
    //         `v1/section/${this.$route.params.id}/${id}/show`
    //       )
    //       .then((res) => {
    //         this.loading=false;
    //         this.section=res.data.data;
    //       })
    //       .catch(() => {
    //         this.section={}
    //       })
    //   }
    // },
    async fetchQuestion(start = true) {
      if (start) {
        this.loading = true;
      } else {
        this.loadingQuestion = true;
      }
      const total_question = this.packets.reduce((carry, current) => {
        const groups = current.groups;
        if (groups) {
          return carry + groups.filter((q) => !!q.group_id).length;
        } else {
          return carry + current.total_question;
        }
      }, 0);
      if (total_question) {
        // this.pageLoading = true;
        if (start) {
          this.loading = true;
        } else {
          this.loadingQuestion = true;
        }
        // var hasGet = 0;
        await this.packets.forEach(async (packet) => {
          if (start) {
            this.loading = true;
          } else {
            this.loadingQuestion = true;
          }
          await this.axios
            .get(
              `/v1/group/${this.$route.params.program}/${this.$route.params.topic}/${this.$route.params.id}/${packet.id}/list`
            )
            .then((res) => res.data)
            .then(async (res) => {
              const groups = res.data.list;
              if (!packet.groups || !packet.groups.length) {
                this.$set(packet, "groups", groups);
              } else {
                packet.groups.forEach((group, index) => {
                  if (group.group_id) {
                    const newGroup = groups.find((v) => v.group_id == group.group_id);
                    if (newGroup) {
                      this.$set(packet.groups, index, newGroup);
                    } else {
                      packet.groups.splice();
                      this.$delete(packet.groups, index);
                    }
                  }
                });
              }

              let list_question = [];

              packet.groups.forEach((group) => {
                // console.table(group.list_question);

                list_question = list_question.concat(group.list_question);
                // this.axios
                //   .get("/bank/v1/question/admin/detail_multiple", {
                //     params: { id: group.list_question.join(",") },
                //   })
                //   .then((res) => res.data)
                //   .then((res) => {});
                // group.list_question.forEach((question, index) => {
                //   let question_id = null;
                //   if (typeof question == "string") {
                //     question_id = question;
                //   } else if (typeof question == "object" && question.id) {
                //     question_id = question.id;
                //   }

                //   if (question_id) {
                //     this.axios
                //       .get(`/bank/v1/question/admin/detail/${question_id}`)
                //       .then((res) => res.data)
                //       .then((res) => {
                //         this.$set(group.list_question, index, res.data);
                // if (++hasGet == total_question) {
                // this.loading = false;
                // }
                //       });
                //   }
                // });

                // console.log(list_question);
                // get data question
              });

              await this.axios.post("/bank/v1/question/admin/detail_multiple", {
                id: list_question
              })
                .then((res) => res.data)
                .then((res) => {
                  // console.log(res.data);
                  packet.groups.forEach((group) => {
                    group.list_question = group.list_question.map((question) => {
                      return res.data.find((v) => v.id == question);
                    });
                    this.loading = false;
                    this.loadingQuestion = false;
                  });
                });

              // console.log(list_question);
            }).catch((error) => {
              console.error(error);
              this.loading = false;
              this.loadingQuestion = false;
            });
        });
      } else {
        this.loading = false;
        this.loadingQuestion = false;
      }
    },
    async createSection(section, paket) {
      if (section.id === null || section.id == undefined) {
        const payload = {
          packet_id: paket.id,
          name: section?.name,
          min_score: section?.min_score,
        };
        return await this.axios.post(
          `/v1/section/${this.$route.params.id}/create`,
          payload
        );
      } else {
        const payload = {
          packet_id: paket.id,
          name: section?.name,
          min_score: section?.min_score,
        };
        return await this.axios.post(
          `/v1/section/${this.$route.params.id}` +
          `/${section.id}/update`,
          payload
        );
      }
    },
    async createPacket() {
      this.loadingPacket = true;
      try {
        const payload = {
          program: this.$route.params.program,
          topic: this.$route.params.topic,
          activity: this.$route.params.id,
          status: "publish",
        };
        await this.$store.dispatch("packet/create", payload);
        await this.fetchPacket();

        // await this.fetchQuestion();
      } catch (error) {
        console.error(error);
      }
      this.loadingPacket = false;
    },
    async deletePacket() {
      this.deleting = true;
      try {
        const payload = {
          program: this.$route.params.program,
          topic: this.$route.params.topic,
          activity: this.$route.params.id,
          id: this.deletePacketModal.packetId,
        };
        await this.$store.dispatch("packet/delete", payload);
        await this.fetchPacket();
        // await this.fetchQuestion();
        this.cancelDeletePacket();
        this.deleting = false;
      } catch (error) {
        this.cancelDeletePacket();
        this.deleting = false;
        console.error(error);
      }
    },
    async deleteGroup() {
      this.deleting = true;
      if (this.deleteGroupModal.groupId.group_id != null) {
        this.axios
          .delete(
            `v1/group/${this.$route.params.program}/${this.$route.params.topic}/${this.$route.params.id}/${this.deleteGroupModal.packetId}/${this.deleteGroupModal.groupId.group_id}/delete`
          )
          .then(async () => {
            await this.deleteSection(this.deleteGroupModal.groupId.section)
              .then((res) => res.data.data)
              .then(async () => {
                this.deleteGroupModal.show = false;
                this.fetchSection();
                this.fetchPacket();
                this.deleting = false;
              })
              .catch(async () => {
                // console.log(error.response)
              });
          });
      } else {
        this.packets[this.deleteGroupModal.paket].groups.splice(this.deleteGroupModal.index, 1);
        this.fetchPacket();

        this.deleting = false;
        this.deleteGroupModal.show = false;
      }
    },
    async deleteSection(section) {
      return await this.axios.delete(
        `v1/section/${this.$route.params.id}` +
        `/${section.id}/delete`
      );
    },
    confirmDeletePacket(packet) {
      this.deletePacketModal.show = true;
      this.deletePacketModal.packetId = packet.id;
    },
    confirmDeleteGroup(paket, grup, index, indexPaket) {
      this.deleteGroupModal.show = true;
      this.deleteGroupModal.packetId = paket;
      this.deleteGroupModal.groupId = grup;
      this.deleteGroupModal.index = index;
      this.deleteGroupModal.paket = indexPaket;
    },
    confirmDeleteQuestion(indexPaket, indexGroup, index) {
      this.delete_question.show = true;
      this.delete_question.indexPaket = indexPaket;
      this.delete_question.indexGroup = indexGroup;
      this.delete_question.index = index;
    },
    cancelDeletePacket() {
      this.deletePacketModal.show = false;
      this.deletePacketModal.packetId = null;
    },
    cancelQuestion() {
      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    deletePaket(id) {
      let index;

      index = this.list_paket.findIndex((list_paket) => list_paket.id == id);
      if (index == -1) {
        index = id;
      }
      this.list_paket.splice(index, 1);

      this.deletePacketModal.show = false;
      this.deletePacketModal.id = null;
    },
    addSection(packet) {
      if (Array.isArray(packet.groups)) {
        packet.groups.push({
          section: {},
          is_group: false,
          list_question: [
            { ...JSON.parse(JSON.stringify(blankQuestion)), type: "multiple_choice" },
          ],
        });

      } else {
        this.$set(packet, "groups", []);
        this.addSection(packet);
      }
    },
    addQuestion(group) {
      // console.log(group) 
      group.list_question.push({ ...JSON.parse(JSON.stringify(blankQuestion)), type: "multiple_choice" });
    },
    packetHasError(packet) {
      if (!(packet.groups && packet.groups.length)) return false;
      return packet.groups.some((group) => !!(group.errors && group.errors.length));
    },

    setListQuestionBySection(list) {
      list = list.map((question) => {
        return question.id;
      }).filter((v) => !!v);
      this.list_question_by_section = list;
    },

    validate() {
      var isValid = true;
      for (let i = 0; i < this.packets.length; i++) {
        const packet = this.packets[i]; // or group
        for (let j = 0; j < packet.groups.length; j++) {
          let questionError = [];
          const group = packet.groups[j];

          console.log("group", group);

          if (group.section.section == undefined && group.section == undefined) {
            questionError.push("Nama section dan nilai minimal tidak boleh kosong");
          } else {

            if (group.section.section.name == null) {
              questionError.push("Nama section tidak boleh kosong");
            }

            if (group.section.section.min_score == null) {
              questionError.push("Nilai minimal tidak boleh kosong");
            }
          }


          for (let k = 0; k < group.list_question.length; k++) {
            // const questionError = [];
            const question = group.list_question[k];

            if (!question.question?.text) {
              questionError.push("Pertanyaan tidak boleh kosong");
            }

            if (!(question.options && question.options.length)) {
              questionError.push("Opsi jawaban tidak boleh kosong");
            }

            if (!(question.correct_answer && question.correct_answer.length)) {
              questionError.push("Jawaban benar tidak boleh kosong");
            }

            if (!question.point) {
              questionError.push("Poin tidak boleh kosong atau 0");
            }

            if (questionError.length) {
              isValid = false;
            }

          }
          group.errors = questionError;
        }
      }
      return isValid;
    },
    async saveGroup(data) {
      // console.log('question',data)
      if (data.group_id) {
        const params = {
          list_question: data.list,
          section_id: data.section.section.id
        }
        return await this.axios.post(
          `/v1/group/${this.$route.params.program}` +
          `/${this.$route.params.topic}` +
          `/${this.$route.params.id}` +
          `/${data.paket}` +
          `/${data.group_id}/update`,
          params
        );
      } else {
        const params = {
          list_question: data.list,
          section_id: data.section_id
        }
        return await this.axios.post(
          `/v1/group/${this.$route.params.program}` +
          `/${this.$route.params.topic}` +
          `/${this.$route.params.id}` +
          `/${data.paket}/create`,
          params
        );
      }
    },

    saveNewQuestion(data, packet, group) {
      let questionError = [];
      if (!data.question?.text) {
        questionError.push("Pertanyaan tidak boleh kosong");
      }

      if (!(data.options && data.options.length)) {
        questionError.push("Opsi jawaban tidak boleh kosong");
      }

      if (!(data.correct_answer && data.correct_answer.length)) {
        questionError.push("Jawaban benar tidak boleh kosong");
      }

      if (!data.point) {
        questionError.push("Poin tidak boleh kosong atau 0");
      }

      data.errors = questionError;
      // return
      if (questionError.length) {
        return;
      }

      // remove media question if null or undefined
      if (data.question.media && data.question.media.length) {
        if (data.question.media[0] === null || data.question.media[0] === undefined) {
          delete data.question.media;
        }
      }

      // remove media options if null or undefined
      data.options.forEach((option, index) => {
        // check if media is null or undefined
        if (option.media && option.media.length) {
          if (option.media[0] === null || option.media[0] === undefined) {
            delete data.options[index].media;
          }
        }
      });

      console.log(data);
      console.log(group, packet);

      this.loadingQuestion = true;
      this.saveQuestion(data)
        .then((res) => res.data.data)
        .then((res) => {
          // if (data.id != res.id) {
          //   this.list_question_by_section = this.list_question_by_section.filter((v) => v != data.id);
          // }

          if (data.id != res.id) {
            this.list_question_by_section.push(res.id);
          }
          // this.loadingQuestion = false;
          // this.fetchQuestion(false);
          this.saveGroup({ ...group, paket: packet.id, list: this.list_question_by_section })
            .then((res) => res.data.data)
            .then((res) => {
              this.loadingQuestion = false;
              Object.assign(group, res);
              this.fetchQuestion(false);
              this.snackbar = {
                state: true,
                text: "Soal berhasil disimpan",
              }
            }).catch((error) => {
              this.loadingQuestion = false;
              this.snackbar = {
                state: true,
                text: error.response.data.message,
              }
            });
        })
        .catch((error) => {
          this.loadingQuestion = false;
          this.snackbar = {
            state: true,
            text: error.response.data.message,
          }
        });
    },


    async saveQuestion(data) {
      const headers = { "content-type": "application/json" };
      if (data.id) {
        return await this.axios.put(`/bank/v1/question/admin/update/${data.id}`, data, {
          headers,
        });
      } else {
        return await this.axios.post(`/bank/v1/question/admin/create`, data, { headers });
      }
    },
    async saveQuestions(paket, group) {
      // console.log('saveQuestions', paket, group);

      // this.loading = true;
      this.loadingSections = true;

      const total_question = group.list_question.length;

      if (!total_question) {
        // this.loading = false;
        this.loadingSections = false;
        group.errors = "Pertanyaan tidak boleh kosong";
        // throw new Error("Pertanyaan tidak boleh kosong");
      }

      if (!this.validate()) {
        // this.loading = false;
        this.loadingSections = false;
        throw new Error("Something bad happen");
      }

      var saved_question = 0;
      this.list_question = []
      try {
        await this.createSection(group.section.section, paket)
          .then((res) => res.data.data)
          .then(async (res) => {
            const section = res.id;
            // console.log('Section',section)

            const question = group.list_question;

            for (let a = 0; a < question.length; a++) {
              const qt = question[a]

              await this.saveQuestion(qt)
                .then((res) => res.data.data)
                .then(async (res) => {
                  this.list_question.push(res.id);

                  if (this.list_question.length == question.length) {
                    let list = this.list_question
                    await this.saveGroup({ ...group, paket: paket.id, section_id: section, list })
                      .then((res) => res.data.data)
                      .then((res) => {
                        Object.assign(group, res);
                        this.fetchPacket();

                        this.snackbar = {
                          state: true,
                          text: "Soal berhasil disimpan",
                        }
                      })
                  }
                  if (++saved_question == total_question) {
                    // jika semua soal dan group sudah tersimpan
                    // this.loading = false;
                    this.loadingSections = false;
                  }
                })
                .catch(async (error) => {
                  this.group.errors = error.response ? error.response.data : error;
                  // this.loading = false;
                  this.loadingSections = false;
                  this.snackbar = {
                    state: true,
                    text: error.response.data.message,
                  }
                  throw error;
                  // reject(error);
                });
            }
          })
          .catch(async (error) => {
            // console.log(error.response)
            const question = group.list_question;

            for (let a = 0; a < question.length; a++) {
              const qt = question[a]

              await this.saveQuestion(qt)
                .then((res) => res.data.data)
                .then(async (res) => {
                  this.list_question.push(res.id);

                  if (this.list_question.length == question.length) {
                    let list = this.list_question
                    await this.saveGroup({ ...group, paket: paket.id, list })
                      .then((res) => res.data.data)
                      .then((res) => {
                        Object.assign(group, res);
                        this.fetchPacket();
                        this.snackbar = {
                          state: true,
                          text: "Soal berhasil disimpan",
                        }
                      })
                  }
                  if (++saved_question == total_question) {
                    // jika semua soal dan group sudah tersimpan
                    // this.loading = false;
                    this.loadingSections = false;
                  }
                })
                .catch(async (error) => {
                  this.group.errors = error.response ? error.response.data : error;
                  // this.loading = false;
                  this.loadingSections = false;
                  this.snackbar = {
                    state: true,
                    text: error.response.data.message,
                  }
                  throw error;
                  // reject(error);
                });
            }
            this.group.errors = "Gagal menyimpan section";
            // throw new Error("Gagal menyimpan section");
            // this.loading = false;
            this.loadingSections = false;
            throw error;
            // const section = group.section.section;

          });
      } catch (error) {
        await this.fetchQuestion();
        // this.loading = false;
        this.loadingSections = false;
        this.snackbar = {
          state: true,
          text: error.response.data.message,
        }
        throw error;
      }

    },
    addGroup(packet) {
      if (packet.groups && packet.groups.length) {
        // packet.groups.push({});
        packet.groups.push({
          is_group: true,
          list_question: [{}],
        });
      } else {
        this.$set(packet, "groups", [{}]);
      }
    },
    addGroupQuestion(question) {
      if (question.list_question && question.list_question.length) {
        question.list_question.push({});
      } else {
        this.$set(question, "list_question", [{}]);
      }
    },
    async deleteQuestion() {
      // this.deleting = true;
      // const paket = this.packets[this.delete_question.indexPaket];
      // const  = this.delete_question.index;

      // try {
      //   // if (question) {
      //   //   await this.axios.delete(
      //   //     `/v1/group/${group.program.id}` +
      //   //       `/${group.topic.id}` +
      //   //       `/${group.activity.id}` +
      //   //       `/${group.packet.id}` +
      //   //       `/${group.group_id}/delete`
      //   //   );
      //   // }
      //   groups.list_question.splice(question, 1);
      //   await this.fetchQuestion();
      // } catch (error) {
      // console.error(paket.groups[this.delete_question.indexGroup]);
      // }
      this.packets[this.delete_question.indexPaket].groups[this.delete_question.indexGroup].list_question.splice(this.delete_question.index, 1);
      this.list_question_by_section.splice(this.delete_question.index, 1);
      this.deleting = false;
      this.delete_question.show = false;
      // this.deleting = false;
      // this.delete_question.show = false;
      // this.delete_question.indexGroup = null;
      // this.delete_question.index = null;
    },
    // getLoad(){        
    //     this.loadingSoal=true
    //     setTimeout(() => {
    //       this.loadingSoal=false;
    //       // alert('Loading')
    //     }, 10000);      
    // }
    doSomething(e) {
      let $ = this;
      let $target = $(e.target), $header = $('.v-expansion-panel__header');
      if ($target.is($header) || $target.parents($header).is($header)) {
        alert('Hello World')
      }
    }
  },
  created() {
    // this.fetchQuestion();              
    this.fetchPacket();
  },
};
</script>
