<template>
  <v-row>
    <v-col v-if="pageLoading" cols="12" class="text-center">
      <v-progress-circular color="primary" indeterminate />
    </v-col>
    <template v-else>
      <v-col v-if="!this.packets.length">
        <v-row justify="center">
          <v-col md="4" sm="6">
            <v-img class="col-10 mx-auto mt-12 mb-4" src="@/assets/images/data_empty.png"></v-img>
            <h6 class="text-h6 text-center">Tidak ada paket soal</h6>
            <p class="text-center text--secondary">
              klik tombol di bawah ini untuk menambahkan paket soal baru
            </p>
            <p class="text-center">
              <v-btn :loading="loadingPacket || loading" color="primary" @click="createPacket">
                <span> Buat Paket Soal </span>
              </v-btn>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else>
        <v-expansion-panels focusable>
          <v-expansion-panel v-for="(paket, index) in packets" cols="12" :key="index" style="border: 1px solid #9db0c6;"
            class="mb-2">
            <v-expansion-panel-header :color="packetHasError(paket) ? 'red lighten-5' : ''" class="font-weight-bold">
              <h3>Paket Soal {{ index + 1 }}</h3>
              <div class="text-right">
                <v-btn :loading="loadingPacket || loading" icon @click.stop="confirmDeletePacket(paket)">
                  <v-icon> $delete </v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-col v-if="loading" cols="12" class="text-center">
                <v-progress-circular color="primary" indeterminate />
              </v-col>
              <template v-else>
                <v-col v-if="!(paket.groups && paket.groups.length)">
                  <v-row justify="center">
                    <v-col md="4" sm="6">
                      <v-img class="col-10 mx-auto mt-12 mb-4" src="@/assets/images/data_empty.png"></v-img>
                      <h6 class="text-h6 text-center">Tidak ada soal</h6>
                      <p class="text-center text--secondary">
                        klik tombol di bawah ini untuk menambahkan soal baru
                      </p>
                      <p class="text-center">
                        <v-btn color="primary" class="mr-2" elevation="0" @click="addQuestion(paket)">
                          Buat Soal
                        </v-btn>
                        <v-menu v-if="false" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-2" elevation="0">
                              Buat Soal
                              <v-icon class="ml-1">$arrdown</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="addQuestion(paket)">
                              <v-list-item-title>Soal Baru</v-list-item-title>
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item @click="addGroup(paket)">
                              <v-list-item-title>Soal Grup</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </p>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-else>
                  <v-col v-if="loadingQuestion" cols="12" class="text-center">
                    <v-progress-circular color="primary" indeterminate />
                  </v-col>
                  <div v-else>
                    <v-expansion-panels focusable>
                      <v-expansion-panel v-for="(group, index2) in paket.groups" :key="index2" cols="12"
                        class="mb-2 rounded" :class="index2 != 0 ? 'mt-2' : 'mt-2'" style="border: 1px solid #9db0c6;">
                        <v-expansion-panel-header
                          :color="group.list_question[0].errors && group.list_question[0].errors.length ? 'red lighten-5' : !group.list_question[0].id ? 'orange lighten-5' : ''"
                          class="font-weight-bold">
                          <div class="d-flex justify-space-between align-center">
                            <div>
                              <h3>
                                {{ group.is_group ? "Group" : "Nomor" }}
                                {{ index2 + 1 }}
                              </h3>
                            </div>
                            <v-btn :loading="loadingQuestion" icon @click.stop="confirmDeleteQuestion(index, index2)">
                              <v-icon> $delete </v-icon>
                            </v-btn>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row no-gutters>
                            <v-col v-if="group.errors && group.errors.length" cols="12">
                              <v-alert type="error" outlined text dense>
                                <ul v-for="(error, indexError) in group.errors" :key="indexError">
                                  <li>{{ error }}</li>
                                </ul>
                              </v-alert>
                            </v-col>
                            <!-- <div v-for="(ques, i) in group.list_question" :key="i">
                              <input-soal
                                v-model="group.list_question[i]"
                                :isloading="loadingQuestion"
                                @save="saveNewQuestion($event, paket, group)"/>
                            </div> -->
                            <input-soal v-model="group.list_question[0]" :isloading="loadingQuestion"
                              @save="saveNewQuestion($event, paket, group)" />
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-alert v-if="!paket.groups[paket.groups.length - 1].list_question[0].id" type="warning" outlined
                      class="mx-0">
                      Pastikan soal sebelumnya sudah disimpan
                    </v-alert>
                    <v-col v-else cols="12">
                      <v-btn v-if="paket.groups[paket.groups.length - 1].list_question[0].id" :disabled="loading"
                        color="primary" class="mr-2" outlined @click="addQuestion(paket)">
                        Tambah Soal
                      </v-btn>
                      <v-menu v-if="false" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-2" outlined>
                            Tambah Soal
                            <v-icon class="ml-1">$arrdown</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="addQuestion(paket)">
                            <v-list-item-title>Soal Baru</v-list-item-title>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item @click="addGroup(paket)">
                            <v-list-item-title>Soal Grup</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </div>
                </v-col>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-col cols="12">
          <v-btn :loading="loadingPacket || loading" outlined color="primary" @click="createPacket">
            <span> <v-icon>$add</v-icon> Tambah paket soal</span>
          </v-btn>
        </v-col>
        <v-dialog v-model="deletePacketModal.show" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span> <v-icon>$warning</v-icon> Hapus Paket Soal </span>
            </v-card-title>
            <v-divider></v-divider>
            <div>
              <v-card-text>
                Apakah anda ingin menghapus paket soal ini ?
              </v-card-text>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="deleting" outlined color="primary" large @click="cancelDeletePacket">
                Batal
              </v-btn>
              <v-btn :loading="deleting" class="white--text" color="primary" large @click="deletePacket">
                Hapus
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="delete_question.show" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span> <v-icon>$warning</v-icon> Hapus Soal </span>
            </v-card-title>
            <v-divider></v-divider>
            <div>
              <v-card-text>
                Apakah anda ingin menghapus soal ini ?
              </v-card-text>
            </div>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn :disabled="deleting" outlined color="primary" large @click="cancelQuestion()">
                Batal
              </v-btn>
              <v-btn :loading="deleting" class="white--text" color="primary" large @click="deleteQuestion">
                Hapus
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </template>

    <v-snackbar top v-model="snackbar.state" :timeout="3000" color="primary" outlined>
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn small icon color="error" v-bind="attrs" @click="snackbar.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
const blankQuestion = {
  instruction: { text: "", media: [] },
  type: "",
  question_type: "text",
  answer_type: "text",
  question: { text: "", media: [] },
  point: 0,
  options: [],
  correct_answer: [],
  status: "publish",
  explanation: {
    correct: { text: "", media: [] },
    wrong: { text: "", media: [] },
  },
  errors: [],
};
import { mapState } from "vuex";
import InputSoal from "./SingleQuestion";
// import { VueEditor } from "vue2-editor";
// import QuestionOptions from "./Options";
// import InputGrupSoal from "./SingleQuestion";

export default {
  components: {
    InputSoal,
    // InputGrupSoal
  },
  data: () => ({
    // questions: [],
    expand: 0,
    pageLoading: null,
    loading: false,
    loadingPacket: false,
    loadingQuestion: false,
    deleting: null,
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["clean"], // remove formatting button
    ],
    deletePacketModal: {
      show: false,
      packetId: null,
    },
    delete_question: {
      show: false,
      id: null,
    },
    snackbar: {
      state: false,
      text: ""
    },
  }),
  computed: {
    types() {
      return ["Multiple Choice"];
    },
    ...mapState("packet", { packets: (state) => state.list }),
  },
  methods: {
    async fetchQuestion(start = true) {
      // console.log("fetching question", this.expand);
      if (start) {
        this.loading = true;
      } else {
        this.loadingQuestion = true;
      }
      const total_question = this.packets.reduce((carry, current) => {
        const groups = current.groups;
        if (groups) {
          return carry + groups.filter((q) => !!q.group_id).length;
        } else {
          return carry + current.total_question;
        }
      }, 0);
      if (total_question) {
        // this.pageLoading = true;
        if (start) {
          this.loading = true;
        } else {
          this.loadingQuestion = true;
        }
        // var hasGet = 0;
        await this.packets.forEach(async (packet) => {
          if (start) {
            this.loading = true;
          } else {
            this.loadingQuestion = true;
          }
          await this.axios
            .get(
              `/v1/group/${this.$route.params.program}/${this.$route.params.topic}/${this.$route.params.id}/${packet.id}/list`
            )
            .then((res) => res.data)
            .then(async (res) => {
              const groups = res.data.list;
              if (!packet.groups || !packet.groups.length) {
                this.$set(packet, "groups", groups);
              } else {
                packet.groups.forEach((group, index) => {
                  if (group.group_id) {
                    const newGroup = groups.find((v) => v.group_id == group.group_id);
                    if (newGroup) {
                      this.$set(packet.groups, index, newGroup);
                    } else {
                      packet.groups.splice();
                      this.$delete(packet.groups, index);
                    }
                  }
                });
              }

              let list_question = [];

              packet.groups.forEach((group) => {
                // console.table(group.list_question);

                list_question = list_question.concat(group.list_question);
                // this.axios
                //   .get("/bank/v1/question/admin/detail_multiple", {
                //     params: { id: group.list_question.join(",") },
                //   })
                //   .then((res) => res.data)
                //   .then((res) => {});
                // group.list_question.forEach((question, index) => {
                //   let question_id = null;
                //   if (typeof question == "string") {
                //     question_id = question;
                //   } else if (typeof question == "object" && question.id) {
                //     question_id = question.id;
                //   }

                //   if (question_id) {
                //     this.axios
                //       .get(`/bank/v1/question/admin/detail/${question_id}`)
                //       .then((res) => res.data)
                //       .then((res) => {
                //         this.$set(group.list_question, index, res.data);
                // if (++hasGet == total_question) {
                // this.loading = false;
                // }
                //       });
                //   }
                // });

                // console.log(list_question);
                // get data question
              });

              await this.axios.post("/bank/v1/question/admin/detail_multiple", {
                id: list_question
              })
                .then((res) => res.data)
                .then((res) => {
                  // console.log(res.data);
                  packet.groups.forEach((group) => {
                    group.list_question = group.list_question.map((question) => {
                      return res.data.find((v) => v.id == question);
                    });
                    this.loading = false;
                    this.loadingQuestion = false;
                  });
                });

              // console.log(list_question);
            }).catch((error) => {
              this.loading = false;
              this.loadingQuestion = false;
              this.snackbar = {
                state: true,
                text: error.response.data.message,
              }
            });
        });
      } else {
        this.loading = false;
        this.loadingQuestion = false;
      }
    },
    async createPacket() {
      this.loadingPacket = true;
      try {
        const payload = {
          program: this.$route.params.program,
          topic: this.$route.params.topic,
          activity: this.$route.params.id,
          status: "publish",
        };
        await this.$store.dispatch("packet/create", payload);
        await this.fetchQuestion();
      } catch (error) {
        console.error(error);
      }
      this.loadingPacket = false;
    },
    async deletePacket() {
      this.deleting = true;
      try {
        const payload = {
          program: this.$route.params.program,
          topic: this.$route.params.topic,
          activity: this.$route.params.id,
          id: this.deletePacketModal.packetId,
        };
        await this.$store.dispatch("packet/delete", payload);
        await this.fetchQuestion();
        this.cancelDeletePacket();
        this.deleting = false;
      } catch (error) {
        this.cancelDeletePacket();
        this.deleting = false;
        console.error(error);
      }
    },
    confirmDeletePacket(packet) {
      this.deletePacketModal.show = true;
      this.deletePacketModal.packetId = packet.id;
    },
    confirmDeleteQuestion(indexPacket, index) {
      this.delete_question.show = true;
      this.delete_question.indexPacket = indexPacket;
      this.delete_question.index = index;
    },
    cancelDeletePacket() {
      this.deletePacketModal.show = false;
      this.deletePacketModal.packetId = null;
    },
    cancelQuestion() {
      this.delete_question.show = false;
      this.delete_question.id = null;
    },
    deletePaket(id) {
      let index;

      index = this.list_paket.findIndex((list_paket) => list_paket.id == id);
      if (index == -1) {
        index = id;
      }
      this.list_paket.splice(index, 1);

      this.deletePacketModal.show = false;
      this.deletePacketModal.id = null;
    },
    addQuestion(packet) {
      if (Array.isArray(packet.groups)) {
        // packet.groups.p,ush({});
        packet.groups.push({
          is_group: false,
          list_question: [
            { ...JSON.parse(JSON.stringify(blankQuestion)), type: "multiple_choice" },
          ],
        });
        // packet.groups[0].list_question.push({ ...JSON.parse(JSON.stringify(blankQuestion)), type: "multiple_choice" });
      } else {
        this.$set(packet, "groups", []);
        this.addQuestion(packet);
      }
    },
    packetHasError(packet) {
      if (!(packet.groups && packet.groups.length)) return false;
      return packet.groups.some((group) => !!(group.errors && group.errors.length));
    },
    validate() {
      var isValid = true;
      for (let i = 0; i < this.packets.length; i++) {
        const packet = this.packets[i]; // or group
        for (let j = 0; j < packet.groups.length; j++) {
          const group = packet.groups[j];
          for (let k = 0; k < group.list_question.length; k++) {
            const questionError = [];
            const question = group.list_question[k];

            if (!question.question?.text) {
              questionError.push("Pertanyaan tidak boleh kosong");
            }

            if (!(question.options && question.options.length)) {
              questionError.push("Opsi jawaban tidak boleh kosong");
            }

            if (!(question.correct_answer && question.correct_answer.length)) {
              questionError.push("Jawaban benar tidak boleh kosong");
            }

            if (!question.point) {
              questionError.push("Poin tidak boleh kosong atau 0");
            }

            if (questionError.length) {
              isValid = false;
            }

            group.errors = questionError;
          }
        }
      }
      return isValid;
    },
    async saveGroup(data) {
      if (data.group_id) {
        return await this.axios.post(
          `/v1/group/${this.$route.params.program}` +
          `/${this.$route.params.topic}` +
          `/${this.$route.params.id}` +
          `/${data.paket}` +
          `/${data.group_id}/update`,
          data
        );
      } else {
        return await this.axios.post(
          `/v1/group/${this.$route.params.program}` +
          `/${this.$route.params.topic}` +
          `/${this.$route.params.id}` +
          `/${data.paket}/create`,
          data
        );
      }
    },
    saveNewQuestion(data, packet, group) {
      let questionError = [];
      if (!data.question?.text) {
        questionError.push("Pertanyaan tidak boleh kosong");
      }

      if (!(data.options && data.options.length)) {
        questionError.push("Opsi jawaban tidak boleh kosong");
      }

      if (!(data.correct_answer && data.correct_answer.length)) {
        questionError.push("Jawaban benar tidak boleh kosong");
      }

      if (!data.point) {
        questionError.push("Poin tidak boleh kosong atau 0");
      }

      data.errors = questionError;
      // return
      if (questionError.length) {
        return;
      }

      // remove media question if null or undefined
      if (data.question.media && data.question.media.length) {
        if (data.question.media[0] === null || data.question.media[0] === undefined) {
          delete data.question.media;
        }
      }

      // remove media options if null or undefined
      data.options.forEach((option, index) => {
        // check if media is null or undefined
        if (option.media && option.media.length) {
          if (option.media[0] === null || option.media[0] === undefined) {
            delete data.options[index].media;
          }
        }
      });

      this.loadingQuestion = true;
      this.saveQuestion(data)
        .then((res) => res.data.data)
        .then((res) => {
          this.saveGroup({ ...group, paket: packet.id, list_question: [res.id] })
            .then((res) => res.data.data)
            .then((res) => {
              // console.log(res);
              // this.loadingQuestion = false;
              Object.assign(group, res);
              this.fetchQuestion();
              this.snackbar = {
                state: true,
                text: "Soal berhasil disimpan",
              }
            });
        })
        .catch(() => {
          // this.loading = false;
          // this.loadingQuestion = false;
          // console.error(error);
          this.snackbar = {
            state: true,
            text: "Soal gagal disimpan",
          }
          this.fetchQuestion();
        });
    },
    async saveQuestion(data) {
      const headers = { "content-type": "application/json" };
      if (data.id) {
        return await this.axios.put(`/bank/v1/question/admin/update/${data.id}`, data, {
          headers,
        });
      } else {
        return await this.axios.post(`/bank/v1/question/admin/create`, data, { headers });
      }
    },
    async saveQuestions() {
      this.loading = true;

      const total_question = this.packets.reduce((carry, item) => {
        return carry + (Array.isArray(item.groups) ? item.groups.length : 0);
      }, 0);

      if (!this.packets.length) {
        this.loading = false;
        throw new Error("Paket soal tidak boleh kosong");
      }

      if (!total_question) {
        this.loading = false;
        throw new Error("Pertanyaan tidak boleh kosong");
      }

      if (!this.validate()) {
        this.loading = false;
        throw new Error("Periksa kembali data pertanyaan yang anda masukkan");
      }

      var saved_question = 0;
      try {
        for (let i = 0; i < this.packets.length; i++) {
          const packet = this.packets[i]; // or group
          for (let j = 0; j < packet.groups.length; j++) {
            const group = packet.groups[j];
            for (let k = 0; k < group.list_question.length; k++) {
              const question = group.list_question[k];

              // remove media question if null or undefined
              if (question.question.media && question.question.media.length) {
                if (question.question.media[0] === null || question.question.media[0] === undefined) {
                  delete question.question.media;
                }

                if (question.question.media[0] && question.question.media[0]._id) {
                  question.question.media[0] = question.question.media[0]._id;
                }
              }

              // remove media options if null or undefined and set media id if exist
              if (question.options && question.options.length) {
                question.options = question.options.map((option) => {
                  if (option.media && option.media.length) {
                    if (option.media[0] === null || option.media[0] === undefined) {
                      delete option.media;
                    }

                    if (option.media[0] && option.media[0]._id) {
                      option.media[0] = option.media[0]._id;
                    }
                  }
                  return option;
                });
              }

              // console.log("question", question);

              const list_question = [];

              await this.saveQuestion(question)
                .then((res) => res.data.data)
                .then(async (res) => {
                  list_question.push(res.id);

                  if (list_question.length == group.list_question.length) {
                    // jika soal di group sudah tersimpan semua
                    await this.saveGroup({ ...group, paket: packet.id, list_question })
                      .then((res) => res.data.data)
                      .then((res) => {
                        Object.assign(group, res);
                      });
                  }

                  if (++saved_question == total_question) {
                    // jika semua soal dan group sudah tersimpan
                    this.loading = false;
                  }
                })
                .catch(async (error) => {
                  this.group.errors = error.response ? error.response.data : error;
                  this.loading = false;
                  throw error;
                  // reject(error);
                });
            }
          }
        }
      } catch (error) {
        await this.fetchQuestion();
        this.loading = false;
        throw error;
      }
    },
    addGroup(packet) {
      if (packet.groups && packet.groups.length) {
        // packet.groups.push({});
        packet.groups.push({
          is_group: true,
          list_question: [{}],
        });
      } else {
        this.$set(packet, "groups", [{}]);
      }
    },
    addGroupQuestion(question) {
      if (question.list_question && question.list_question.length) {
        question.list_question.push({});
      } else {
        this.$set(question, "list_question", [{}]);
      }
    },
    async deleteQuestion() {
      this.deleting = true;
      const groups = this.packets[this.delete_question.indexPacket].groups;
      const group = groups[this.delete_question.index];
      try {
        if (group.group_id) {
          await this.axios.delete(
            `/v1/group/${group.program.id}` +
            `/${group.topic.id}` +
            `/${group.activity.id}` +
            `/${group.packet.id}` +
            `/${group.group_id}/delete`
          );
        }
        groups.splice(this.delete_question.index, 1);
        await this.fetchQuestion(false);
      } catch (error) {
        console.error(error);
      }

      this.deleting = false;
      this.delete_question.show = false;
      this.delete_question.indexPacket = null;
      this.delete_question.index = null;
    },
  },
  created() {
    this.fetchQuestion();
  },
};
</script>